/** write custom css before the node modules bootstrap.scss @import statement **/
@import "../../node_modules/bootstrap/scss/bootstrap.scss"; // import here first as well to get access to variables

/* colors */
$navMid: #232f3e;
$navDark: #18202c;
$navLink: #b9bcc0;
$materialBlue: #009be5;
$materialGray: #eaeff1;

$theme-colors: (
        "navMid": $navMid,
        "navDark": $navDark,
        "navLink": $navLink,
        "materialBlue": $materialBlue,
        "materialGray": $materialGray,
        "muted": $gray-600, // muted already exists as a bootstrap color for most cases, but can't be used in some instances, such as "border-muted", so added it here to make it available
);

/* Button */
$btn-border-radius: 10px;
$btn-padding-x: 15px;

/* Card */
.card {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 10px !important;
}

.tooltip { pointer-events: none; }

@function get-breakpoints($key: "md") {
  @return map-get($grid-breakpoints, $key);
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.w-md-75 {
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
}

.w-lg-60 {
  @include media-breakpoint-up(lg) {
    width: 60% !important;
  }
}
