@import "./bootstrap-override";
@import "./sidebar.scss";
@import "./news.scss";
@import "./useful-links";
@import "./article-market.scss";
@import "./tables.scss";
@import "./modals.scss";
@import "./statistics-card.scss";
@import "./tooltip.scss";
@import "./graphs.scss";
@import "./checkbox.scss";
@import "./video-player.scss";
@import "./financial-summary.scss";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $materialGray;
}

h1.skinny, h2.skinny, h3.skinny, h4.skinny, h5.skinny, h6.skinny {
  font-weight: 400 !important; // closer to the alpha build font
}

.word-break-break-word {
  word-break: break-word !important;
}

.white-space-pre {
  white-space: pre;
}

th:focus {
  outline: none !important;
}

.log-in-background-image {
  background-image: url("/images/vancouver-skyline-dusk.png");
  background-size: cover;
}

.md-fluid-container {
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}
