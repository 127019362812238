.react-bootstrap-table {
  overflow-x: scroll;

  table {
    table-layout: initial !important;
    overflow-x: scroll;
  }

  th {
    white-space: nowrap;
    font-size: 9pt !important;
    font-weight: normal;
  }

  td {
    white-space: nowrap;
    font-size: 11pt;
  }
}
