@import "./bootstrap-override";

.clickable-row-base {
  @extend .d-flex, .justify-content-between, .align-items-center, .mb-2, .py-2, .px-3;

  .cr-label-span {
    h5 {
      @extend .skinny,
    }
  }

  .cr-value-span {
    h5 {
      @extend .font-weight-bold, .d-flex, .align-items-center;
    }
  }

  h5 {
    margin-bottom: 0;
  }
}

.clickable-row {
  @extend .clickable-row-base;

  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $materialGray;
  }
}

.selected-row {
  @extend .clickable-row;
  background-color: $materialGray;
}

.breakdown-item {
  @extend .d-flex, .justify-content-between, .align-items-center, .mb-1, .py-2, .px-3;

  h6 {
    @extend .mb-0;
  }

  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $materialGray;
  }
}
