.article-market-item {
  cursor: pointer;
  transition: 0.5s;
}

.article-market-item:hover .article-market-thumbnail {
  transform: scale(1.03);
}

.article-market-thumbnail {
  transition: 0.5s;
}
