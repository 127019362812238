.graph-container {
  overflow-x: auto;
}

.graph-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.graph-container::-webkit-scrollbar:vertical {
  width: 0px;
}

.graph-container::-webkit-scrollbar:horizontal {
  height: 11px;
}

.graph-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}
